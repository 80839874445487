import './Footer.css'
function Footer() {
    return(
        <div className='footer'>
            <div class="copyright">
                <p>© 2023 NathanG, Inc.</p>
            </div>
        </div>
    );
}

export default Footer;
